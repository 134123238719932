export const api = {
    login: '/api/auth',
    logout: '/api/logout',
    refreshToken: '/api/refresh',
    eventListG: '/api/guest/events',
    raceListG: '/api/guest/races',
    eventListNewG: '/api/guest/event_list',
    eventDetailG: '/api/guest/event',
    eventList: '/api/client/events',
    eventListNew: '/api/client/event_list',
    raceList: '/api/client/races',
    eventDetail: '/api/client/event',
    eventDetailOnly: '/api/client/event_detail',
    userData: '/ws/getUserData',
    odds: '/ws/getMarketData',
    oddsNew: '/ws/getMarketDataNew',
    exposure: '/api/client/exposes',
    accountStmt: '/api/client/report/statement',
    profitLoss: '/api/client/report/profit_loss',
    profitLossDetail: '/api/client/report/profit_loss_detail',
    betHistory: '/api/client/report/order_history',
    sportsBookBetHistory: '/api/client/sports-book/orders',
    eventTypeIds: '/api/client/event-type-id/list',
    fancyRunPosition: '/api/client/fancy_run_position',
    scoreData: '/ws/getScoreData',
    tvData: '/api/client/stream',
    ftLivetvData: 'https://e765432.xyz/static/01d4ca46f942a016164482a75bebdbbb264e8740/getdata.php?chid=',
    placeBet: '/api/client/store_order',
    orderList: '/api/client/orders',
    deletedOrderList: '/api/client/report/settled/deleted_order_history',
    stakesUpdate: '/api/client/update_stakes',
    casino1: '/api/client/casino_1',
    casino1G: '/api/guest/casino_1',
    casinoIG: '/api/guest/casino_i',
    casinoIList: '/api/client/casino_i_list_new',
    casinoIListG: '/api/guest/casino_i',
    // casinoITbl: '/api/client/casino_i_new',
    casinoITbl: '/api/client/casino_i_new_2',
    // casinoIntList: '/api/client/casino_int_list_new',
    casinoIntList: '/api/client/casino_int_list_new_2',
    casinoIntListG: '/api/guest/casino_int',
    casinoIntTbl: '/api/client/casino_int_new',
    casinoIntVivo: '/api/client/casino_vivo',
    changePass: '/api/change_password',
    myProfile: '/api/client/profile',
    rules: '/api/client/rules',
    menu: '/api/guest/menu',
    marketAnalysis: '/api/client/market-analysis',
    multiMarket: '/api/client/multi-market',
    saveMultiMarket: '/save',
    removeMultiMarket: '/remove',
    cashOut: '/api/client/cash-out',
    primiumCricket: '/api/client/sports-book-new',
    sportsBook: '/api/client/sports-book',
    sportsBookNew: '/api/client/sports-book-new/1',
    deposit: '/api/client/get_deposit',
    withdraw: '/api/client/get_withdraw',
    bonusList: '/api/client/bonus-list',
    bonusRedeem: '/api/client/bonus-redeem',
    bonusRules: '/api/client/bonus_rules',
    refferAndEarn: '/api/client/refer-and-earn'
};
