export const environment = {
  production: false,
  panel: "ls_",
  domain: "bingobook247.com",
  baseUrl: "https://api.bingobook247.com",
  trafficTag: '',
  isMobile: true,
  ludoGameId: '1521',
  demoLoginUserName: 'demo123',
  isBetOpenEnv: false,
  pollers: {
    oddsData: 600,
    listOddsData: 10000,
    scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  eventHighLight: [28569726],
  imgPrefix: '/king_client',
  guestEnable: false,
  mobileWidth: 768,
  wpLinkShowPages: '/sports, /home, /inplay',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  host: {
    domain: '',
    apiDomain: '',
    name: '',
    dName: '',
    ext: '',
    theme: '',
    email: '',
    social: {
      isShow: false,
      whatsApp: '',
      instagram: '',
      telegram: ''
    },
    pusher: {
      key: '',
      cluster: ''
    },
    ftLivetvData:'',
    guestEnable: false,
    demoLoginWpLink: '',
    wpLink: '',
    forgotWaLink: '',
    depoWithdraw: false,
    downloadApk: '',
    themePath: ['assets/css/theme-master.css'],
    iframeDomainUrl: '',
    signUpActive: false,
    demoDepoWithdrawLink: '',
    chatScript: {
      src: '',
      attr: {},
      id: '',
      username: '',
      // name: ''
    },
  },
  hosts: [
    {
      domain: 'localhost',
      apiDomain: 'localhost',
      name: 'localhost',
      dName: 'LocalHost',
      ext: '.com',
      theme: 'blue-body',
      email: 'testemail@gmail.com',
      social: {
        isShow: false,
        whatsApp: '',
        instagram: '',
        telegram: ''
      },
      pusher: {
        key: '057170df2af18ee833fc',
        cluster: 'ap2'
      },
      ftLivetvData:'',
      guestEnable: true,
      demoLoginWpLink: '',
      wpLink: 'https://wa.link/reddycard',
      forgotWaLink: '',
      depoWithdraw: true,
      downloadApk: '',
      themePath: ['assets/css/theme-master.css'],
      iframeDomainUrl: 'https://dada247.com/',
      signUpActive: true,
      demoDepoWithdrawLink: '',
      chatScript: {
        src: '',
        attr: {},
        id: '',
        username: '',
        // name: ''
      },
    },
    {
      domain: 'bingobook247.com',
      apiDomain: 'bingobook247.com',
      name: 'bingobook247',
      dName: 'bingobook247',
      ext: '.com',
      theme: 'yellow-body',
      email: '',
      social: {
        isShow: false,
        whatsApp: '',
        instagram: '',
        telegram: ''
      },
      pusher: {
        key: 'c2347e49e0d3152e8020',
        cluster: 'ap2'
      },
      ftLivetvData:'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      guestEnable: true,
      demoLoginWpLink: '',
      wpLink: '',
      forgotWaLink: '',
      depoWithdraw: false,
      downloadApk: '',
      themePath: ['assets/css/theme-master.css'],
      iframeDomainUrl: 'https://panel.freeplay24.com/',
      signUpActive: true,
      demoDepoWithdrawLink: '',
      afterLoginNoticePath: '',
      chatScript: {
        src: '',
        attr: {},
        id: '',
        username: '',
        // name: ''
      },
    },
  ]
};
